import React, { useState, useContext, useMemo, forwardRef, createRef } from "react";
import QuestCard from "./QuestCard";
import Masonry from '@mui/lab/Masonry';
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import {SettingsContext} from "../../../common/SettingsContext";
import {DataContext} from "../../../common/DataContext";
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 2, 0)
    },
    point: {
        margin: theme.spacing(0.5),
    },
    subtitle: {
        textAlign: 'right',
        display: 'inline',
        flexGrow: 1
    },
    toggles: {
        marginLeft: theme.spacing(1)
    },
    refresh: {
        transition: theme.transitions.create(["transform"], {
          duration: theme.transitions.duration.short
        })
    },
    refreshOn: {
        transform: "rotate(-180deg)"
    },
    refreshOff: {
        transform: "rotate(0)"
    }
}));

export default function QuestsGrid(props) {
    const [size, setSize] = useState(0);

    const [playerCounts, setPlayerCounts] = useState({});
    const [fetchingPlayerCount, setFetchingPlayerCount] = useState(false);
    const [timeoutPlayerCount, setTimeoutPlayerCount] = useState(false);

    const [fetchingQuestData, setFetchingQuestData] = useState(false);
    const [timeoutQuestData, setTimeoutQuestData] = useState(false);

    const { questData, setQuestData, genericData } = useContext(DataContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { settings, toggleQuestsProgress, toggleCompletedQuests } = useContext(SettingsContext);
    
    const classes = useStyles();


    const CustomGridItemComponent = forwardRef(({style, className, key, children}, ref) => {
        return (
            <div
                key={key}
                style={style}
                className={className}
                ref={ref}
            >
                {children}
            </div>
        );
    });

    const gridChildren = useMemo(() => (
        props.orderedGames.filter((name, key) =>
            checkCard(name, key)).map((name, key) =>
                renderCard(name, key))
    ), [props.orderedGames, props.quests]);

    function checkCard(name, key) {
        let showCompletedCard = true;
        if (!settings.quests.completed)
            for (let quest of props.quests[name].quests)
                if (!quest.status.completed) showCompletedCard = false;

        return !props.quests[name].legacy && (settings.quests.completed || !showCompletedCard)
    }

    function renderCard(name, key) {
        return (
            <QuestCard
                settings={settings}
                size={size}
                game={props.quests[name]}
                players={playerCounts.hasOwnProperty(convertGameNames(name.toUpperCase()))
                    ? playerCounts[convertGameNames(name.toUpperCase())].players
                    : playerCounts?.LEGACY?.modes
                        ? playerCounts.LEGACY.modes[convertGameNames(name.toUpperCase())]
                        : undefined
                }
            />
        )
    }

    function convertGameNames(key) {
        switch (key) {
            case "SUPERSMASH": 
                return "SUPER_SMASH"
            case "MURDERMYSTERY":
                return "MURDER_MYSTERY"
            case "QUAKE":
                return "QUAKECRAFT"
            case "BUILDBATTLE":
                return "BUILD_BATTLE"
            case "HUNGERGAMES":
                return "SURVIVAL_GAMES"
            default:
                return key
        }
    }

    function refreshPlayerCount() {
        setFetchingPlayerCount(true);
        setTimeoutPlayerCount(true);
        axios.get("/api/misc/gameCounts")
            .then((response) => {
                setPlayerCounts(response.data.games)
            })
            .finally(() => {
                setFetchingPlayerCount(false)
                setTimeout(() => setTimeoutPlayerCount(false), 5000);
            });
    }

    function refreshQuestData() {
        setFetchingQuestData(true);
        setTimeoutQuestData(true);
        const loadingKey = enqueueSnackbar("Loading latest data...", {variant: "info", persist: true});
        axios.get(`/api/quests/player_simple/${genericData.uuid}?meta=true`)
            .then((response) => {
                if (response.data.success) {
                    enqueueSnackbar("Success", {variant: "success"})
                    questData.quests = response.data.data.quests
                    questData.today.dailies = response.data.data.today.dailies;
                    questData.today.weeklies = response.data.data.today.weeklies;
                    questData.today.possible_dailies = response.data.data.today.possible_dailies;
                    questData.today.possible_weeklies = response.data.data.today.possible_weeklies;
                    setQuestData(questData)
                }
                
            })
            .finally(() => {
                closeSnackbar(loadingKey);
                setFetchingQuestData(false);
                setTimeout(() => setTimeoutQuestData(false), 10000);
            });
    }

    function onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
    }

    function saveToLS(key, value) {
        if (global.localStorage) {
            global.localStorage.setItem(
                "rgl-8",
                JSON.stringify({
                    [key]: value
                })
            );
        }
    }

    return (
        <div>
            <span className={classes.flex}>
                <Grid className={classes.toggles}>
                    <Grid item>
                        <FormControlLabel control={
                            <Switch
                                checked={settings["quests"]["progress"]}
                                onChange={toggleQuestsProgress}
                                color="primary"
                                name="progressChecked"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        } label={"Toggle Progress"} />
                        <FormControlLabel control={
                            <Switch
                                checked={settings["quests"]["completed"]}
                                onChange={toggleCompletedQuests}
                                color="primary"
                                name="progressChecked"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        } label={"Show completed"} />
                        <FormControlLabel
                            control={
                                <IconButton
                                    disabled={timeoutQuestData}
                                    onClick={refreshQuestData}
                                    color="primary"
                                >
                                    <CachedIcon
                                        className={[
                                            classes.refresh,
                                            fetchingQuestData
                                                ? classes.refreshOn
                                                : classes.refreshOff
                                        ].join(" ")}
                                    />
                                </IconButton>
                            }
                            label={"Refresh Quest Data"}
                        />
                        <FormControlLabel
                            control={
                                <IconButton
                                    disabled={timeoutPlayerCount}
                                    onClick={refreshPlayerCount}
                                    color="primary"
                                >
                                    <SupervisedUserCircleIcon
                                        className={[
                                            classes.refresh,
                                            fetchingPlayerCount
                                                ? classes.refreshOn
                                                : classes.refreshOff
                                        ]}
                                    />
                                </IconButton>
                            }
                            label={(Object.keys(playerCounts).length === 0 ? "Show" : "Refresh") + " Player Counts"}
                        />
                    </Grid>
                </Grid>
                    <Typography variant={"h6"} className={classes.subtitle}>
                    {`${props.today[props.type]} / ${props.today["possible_"+props.type]} completed`}
                </Typography>
            </span>

            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
                {gridChildren}
            </Masonry>
        </div>
    )
}
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import IndividualQuest from "./InvididualQuest";
import Chip from "@mui/material/Chip";
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1, 2, 2, 2),
            marginLeft: theme.spacing(0.75),
            marginRight: theme.spacing(0.75),
            marginBottom: theme.spacing(1.5),
        },
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
    },
    game: {
        [theme.breakpoints.up('xs')]: {
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(0.6),
            paddingTop: theme.spacing(0),
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    grow: {
        flexGrow: 1,
    },
    collapseAll: {
        color: theme.palette.text.secondary,
    }
}));

export default function QuestCard(props) {
    const classes = useStyles();
    const [accordionState, setAccordionState] = useState(getInitialAccordionState());
    const [accordionsExpanded, setAccordionsExpanded] = useState(false);

    function getInitialAccordionState() {
        let state = {};

        for (let quest of props.game.quests) {
            state[quest.id] = false;
        }

        return state;
    }

    function toggleAccordion(id) {
        let state = JSON.parse(JSON.stringify(accordionState));
        state[id] = !state[id];
        setAccordionState(state);
    }

    function toggleAllAccordions() {
        let state = {};

        for (let quest of props.game.quests) {
            state[quest.id] = !accordionsExpanded;
        }

        setAccordionState(state);
        setAccordionsExpanded(!accordionsExpanded);
    }

    return (
        <Paper key={props.key} className={classes.paper} elevation={props.size}>
            <span className={classes.game}>
                <div className={classes.grow}/>
                <Typography varient={"h4"}>
                    <Button className={classes.collapseAll} onClick={toggleAllAccordions}>
                        {props.game.name}
                    </Button>
                </Typography>
                <div className={classes.grow}/>
                {props.players &&
                    <Chip
                        variant={"outlined"}
                        size="small"
                        label={props.players + " players"}
                        color={"primary"}
                    />
                }
            </span>
            <div>
                {props.game["quests"].filter((quest) => props.settings["quests"]["completed"] || (!props.settings["quests"]["completed"] && !quest.status.completed)).map((quest) => (
                    <IndividualQuest
                        key={quest.id}
                        expanded={accordionState[quest.id]}
                        quest={quest}
                        elevation={props.size}
                        toggleAccordion={toggleAccordion}
                    />
                ))}
            </div>
        </Paper>
    )
}

import React, {useContext, useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { withStyles } from '@mui/styles';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import HoverChip from "./HoverChip";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import {SettingsContext} from "../common/SettingsContext";
import TextField from "@mui/material/TextField";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useSnackbar } from 'notistack';
import { BookmarkSnackbar } from './BookmarkSnackbar';
import { useRouteMatch } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
        display: 'inline',
        marginBottom: theme.spacing(0.5)
    },
    table: {
        width: '100%'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    point: {
        margin: theme.spacing(0.5),
    },
    subtitle: {
        textAlign: 'right',
        display: 'inline',
        flexGrow: 1
    },
    filters: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1)
    },
    centerVertical: {
        display: "flex",
        alignItems: "center",
    },
    highlight: {
        backgroundColor: theme.palette.primary.main
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    sectionTitle: {
        padding: theme.spacing(0, 0, 1, 0.5)
    },
    tableSection: {
        margin: theme.spacing(1, 0, 2, 0)
    },
    alignMid: {
        display: 'inline-flex',
        verticalAlign: 'middle'
    },
    materialIcons: {
        display: 'inline-flex',
        verticalAlign: 'middle',
    },
    pointer: {
        cursor: 'pointer',
        "&:hover": {
            color: theme.palette.text.disabled
        }
    },
    bookmarkCell: {
        paddingLeft: theme.spacing(1)
    },
    bookmarkButton: {
        marginRight: theme.spacing(1)
    },
    anchorParent: {
        display: 'block',
        width: 0
    },
    anchor: {
        paddingTop: 70,
        marginTop: -70,
    },
    selectedAnchor: {
        backgroundColor: '#add8e6b3 !important',
    },
}));

export default function AchievementsList(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { url } = useRouteMatch();

    const sorts = {
        alphabetical: "name",
        desc: "description",
        points: "points",
        globalUnlock: "globalPercentUnlocked",
        gameUnlock: "gamePercentUnlocked",
        tiers: "tiers_completed"
    }

    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState(1); // 1: DESC, 0: ASC

    const type = Object.freeze({both:"Both", one_time:"One time", tiered:"Tiered"})
    const status = Object.freeze({both:"Both", complete:"Complete", incomplete:"Incomplete"})
    const size = Object.freeze({narrow:"Narrow", wide:"Wide"})

    const [search, setSearch] = useState("");

    const {settings, toggleBookmark, handleStatusAndTypeChange} = useContext(SettingsContext);

    useEffect(() => {
        if (window.location.hash) {
            handleStatusAndTypeChange(type.both, status.both);
        }
    }, [])
    
    useEffect(() => {
        if (window.location.hash) {
            const anchor = document.querySelector(window.location.hash);
            if (anchor)
                anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }, [window.location.hash])

    useEffect(() => {
        if (window.location.hash
            && settings.achievements.type === type.both
            && settings.achievements.status === status.both
            ) {
                const anchor = document.querySelector(window.location.hash);
                if (anchor)
                    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }, [settings.achievements])


    function handleAnchorClick(event, anchorName) {
        if (["button", "svg", "path", "span"].indexOf(event.target.nodeName.toLowerCase()) === -1) {
            if (window.location.hash === "#" + anchorName) window.location.hash = "";
            else window.location.hash = "#" + anchorName;
        }
    }

    function handleBookmarkClick(achievement_type, achievement) {

        enqueueSnackbar("", {
            variant: "info",
            content: <BookmarkSnackbar
                        url={url}
                        type={"info"}
                        icon={isBookmarked(achievement_type, achievement)
                            ? "remove"
                            : "add"
                        }
                        message={isBookmarked(achievement_type, achievement)
                            ? `Removed ${achievement} from Bookmarks`
                            :`Added ${achievement} to Bookmarks`
                        }
                        extra={"Extra info"}
                    />
        });
            

        toggleBookmark(props.game, props.category, achievement_type, achievement);
    }

    function isBookmarked(achievement_type, achievement) {
        return settings.bookmarks[props.category]?.[props.game]?.[achievement_type]?.hasOwnProperty(achievement) ? true : false;
    }

    const StyledTableHeader = withStyles((theme) => ({
        root: {
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
            borderWidth: 2
        },
    }))(TableRow);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            '& .MuiChip-label': {
                cursor: 'text !important'
            },
            '&:hover': {
                backgroundColor: '#add8e636',
            },
            cursor: 'pointer'
        },
    }))(TableRow);

    function searchText(text) {
        return (
            search !== ""
                ? text.split(new RegExp(search, "ig")).map((chunk, index) => (
                    <span>{chunk}<mark className={classes.highlight}>{index !== text.split(new RegExp(search, "ig")).length-1 && search}</mark></span>
                ))
                : text
        )
    }

    function subPercentages(text, value) {
        return text.replace("%%value%%", value)
    }

    function handleSort(sort) {
        setSortBy(sort);
        sortBy === sort ? setOrderBy(1-orderBy) : setOrderBy(1)
    }

    function getTieredResults() {
        let found = false;
        const results = Object.keys(props.achievements["tiered"]).sort((achievement1, achievement2) => (
            orderBy === 1 ?
                (props.achievements["tiered"][achievement1][sortBy] > props.achievements["tiered"][achievement2][sortBy] ? 1 : -1) :
                (props.achievements["tiered"][achievement1][sortBy] < props.achievements["tiered"][achievement2][sortBy] ? 1 : -1)
            )).map((achievement) => {
            if (props.achievements["tiered"][achievement]["legacy"] !== true &&
                (search === "" || props.achievements["tiered"][achievement]["description"].toUpperCase().includes(search.toUpperCase()) || props.achievements["tiered"][achievement]["name"].toUpperCase().includes(search.toUpperCase())) &&
                ((settings["achievements"]["status"] !== status.incomplete && props.achievements["tiered"][achievement]["completed"]) ||
                    (settings["achievements"]["status"] !== status.complete && !props.achievements["tiered"][achievement]["completed"]))) {
                found = true;
                return (
                    <StyledTableRow
                        button
                        onClick={(event) => handleAnchorClick(event, achievement)}
                        key={achievement}
                        className={window.location.hash && achievement === window.location.hash.substring(1) && classes.selectedAnchor}
                    >
                        <TableCell className={classes.bookmarkCell}>
                            <div id={achievement} className={classes.anchorParent}>
                                <div className={classes.anchor}/>
                            </div>
                            <IconButton className={classes.bookmarkButton} onClick={() => handleBookmarkClick("tiered", achievement)}>
                                {isBookmarked("tiered", achievement) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                            </IconButton>
                            {searchText(props.achievements["tiered"][achievement]["name"])}
                        </TableCell>
                        <TableCell>
                            {searchText(props.achievements["tiered"][achievement]["description"].split('%%value%%')[0])}
                            <Chip size="small" label={
                                Math.min(props.achievements["tiered"][achievement]["next_goal"], props.achievements["tiered"][achievement]["progress"])
                                + "/" +
                                props.achievements["tiered"][achievement]["next_goal"]}/>
                            {searchText(props.achievements["tiered"][achievement]["description"].split('%%value%%')[1])}
                        </TableCell>
                        <TableCell className={classes.points}>
                            {props.achievements["tiered"][achievement]["tiers"].map((tier) => 
                                <HoverChip
                                    tier={tier}
                                />
                            )}
                        </TableCell>
                    </StyledTableRow>
                )
            }
        })
        return found ? results :
            <TableRow>
                <TableCell colSpan={100} align={"center"}>
                    No results that match the filter
                </TableCell>
            </TableRow>;
    }

    function getOneTimeResults() {
        let found = false;
        const results = Object.keys(props.achievements["one_time"]).sort((achievement1, achievement2) => (
            !props.achievements["one_time"][achievement1].hasOwnProperty(sortBy) ? true :
                (orderBy === 1 ?
                    (props.achievements["one_time"][achievement1][sortBy] > props.achievements["one_time"][achievement2][sortBy] ? 1 : -1) :
                    (props.achievements["one_time"][achievement1][sortBy] < props.achievements["one_time"][achievement2][sortBy] ? 1 : -1))
        )).map((achievement) => {
            if (props.achievements["one_time"][achievement]["legacy"] !== true &&
                (search === "" ||
                    props.achievements["one_time"][achievement]["description"].toUpperCase().includes(search.toUpperCase()) ||
                    props.achievements["one_time"][achievement]["name"].toUpperCase().includes(search.toUpperCase())) &&
                ((settings["achievements"]["status"] !== status.incomplete && props.achievements["one_time"][achievement]["completed"]) ||
                    (settings["achievements"]["status"] !== status.complete && !props.achievements["one_time"][achievement]["completed"]))) {
                found = true;
                return (
                    <StyledTableRow
                        onClick={(event) => handleAnchorClick(event, achievement)}
                        key={achievement}
                        className={window.location.hash && achievement === window.location.hash.substring(1) && classes.selectedAnchor}
                    >
                        <TableCell className={classes.bookmarkCell}>
                            <div id={achievement} className={classes.anchorParent}>
                                <div className={classes.anchor}/>
                            </div>
                            <IconButton className={classes.bookmarkButton} onClick={(e) => handleBookmarkClick("one_time", achievement)}>
                                {isBookmarked("one_time", achievement) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                            </IconButton>
                            {searchText(props.achievements["one_time"][achievement]["name"])}
                        </TableCell>
                        <TableCell>
                            {searchText(props.achievements["one_time"][achievement]["description"])}
                        </TableCell>
                        <TableCell>
                            <Chip size="small" style={{color: "white", backgroundColor: `${props.achievements["one_time"][achievement]["completed"] ? theme.palette.success.main : theme.palette.error.main}`}} label={props.achievements["one_time"][achievement]["points"]} />
                        </TableCell>
                        {isNormalGame() &&
                        <TableCell align={"center"}>
                            <Chip
                                size="small"
                                label={props.achievements["one_time"][achievement].hasOwnProperty("gamePercentUnlocked") ?
                                    props.achievements["one_time"][achievement]["gamePercentUnlocked"].toLocaleString()+"%" :
                                    "0%"
                                }
                            />
                        </TableCell>}
                        <TableCell align={"center"}>
                            <Chip
                                size="small"
                                label={props.achievements["one_time"][achievement].hasOwnProperty("globalPercentUnlocked") ?
                                    props.achievements["one_time"][achievement]["globalPercentUnlocked"].toLocaleString()+"%" :
                                    "0%"}
                            />
                        </TableCell>
                    </StyledTableRow>
                )
            }
        })
        return found
            ? results
            : <TableRow>
                <TableCell colSpan={100} align={"center"}>
                    No results that match the filter
                </TableCell>
            </TableRow>;
    }

    function isNormalGame() {
        let found = false;
        for (let achievement of Object.values(props.achievements["one_time"])) {
            if (!achievement.hasOwnProperty("legacy") && achievement.hasOwnProperty("gamePercentUnlocked")) {
                found = true;
                break
            }
        }
        return found
    }

    return (
        <div className={classes.root}>
            <span className={classes.flex}>
                <Typography variant={"h4"} className={classes.title}>{props.achievements["name"]} {props.stats["earned_achievements"] === props.stats["total_achievements"] && <Tooltip title="Complete"><CheckCircleIcon/></Tooltip>}</Typography>
                <Typography variant={"h6"} className={classes.subtitle}>{` ${Math.floor((props.stats["earned_achievements"] / props.stats["total_achievements"])*100)}% complete`}</Typography>
            </span>
            <FilterAchievements
                type={type}
                status={status}
                size={size}
                onChange={(e) => setSearch(e.target.value)}
            />
            {settings["achievements"]["type"] !== type.one_time &&
            <div className={classes.tableSection}>
                <Typography className={classes.sectionTitle} variant="h6" id="tableTitle" component="div">
                    Tiered achievements
                </Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size={settings["achievements"]["size"] === size.narrow ? "small" : "medium"}>
                        <TableHead>
                            <StyledTableHeader>
                                <TableCell align="left" onClick={() => handleSort(sorts.alphabetical)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.alphabetical ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Name"}
                                    </span>
                                </TableCell>
                                <TableCell align="left" onClick={() => handleSort(sorts.desc)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.desc ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Description"}
                                    </span>
                                </TableCell>
                                <TableCell align="left" onClick={() => handleSort(sorts.tiers)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.tiers ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Tiers"}
                                    </span>
                                </TableCell>
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody>
                            {getTieredResults()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
            {settings["achievements"]["type"] !== type.tiered &&
            <div className={classes.tableSection}>
                <Typography className={classes.sectionTitle} variant="h6" id="tableTitle" component="div">
                    One time achievements
                </Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size={settings["achievements"]["size"] === size.narrow ? "small" : "medium"}>
                        <TableHead>
                            <StyledTableHeader>
                                <TableCell align="left" onClick={() => handleSort(sorts.alphabetical)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.alphabetical ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Name"}
                                    </span>
                                </TableCell>
                                <TableCell align="left" onClick={() => handleSort(sorts.desc)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.desc ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Description"}
                                    </span>
                                </TableCell>
                                <TableCell align="left" onClick={() => handleSort(sorts.points)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.points ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Points"}
                                    </span>
                                </TableCell>
                                {isNormalGame() &&
                                <TableCell align="left" onClick={() => handleSort(sorts.gameUnlock)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.gameUnlock ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {`${props.achievements["name"]} Players Unlocked`}
                                    </span>
                                </TableCell>}
                                <TableCell align="left" onClick={() => handleSort(sorts.globalUnlock)} className={classes.pointer}>
                                    <span className={classes.alignMid}>
                                        {sortBy === sorts.globalUnlock ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                        {"Global Players Unlocked"}
                                    </span>
                                </TableCell>
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody>
                            {getOneTimeResults()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
        </div>
    );
}

function FilterAchievements(props) {
    const classes = useStyles();

    return (
        <SettingsContext.Consumer>
            {({settings, handleTypeChange, handleStatusChange, handleSizeChange}) => (
                <Grid container spacing={1} className={classes.filters}>
                    <Grid item xs={12} sm>
                        <FormControl color={"primary"} component="fieldset">
                            <FormLabel component="legend">Type</FormLabel>
                            <RadioGroup row aria-label="type" name="type" value={settings["achievements"]["type"]} onChange={(e) => handleTypeChange(e.target.value)}>
                                <FormControlLabel value={props.type.both} control={<Radio color={"primary"} />} label={props.type.both} />
                                <FormControlLabel value={props.type.one_time} control={<Radio color={"primary"} />} label={props.type.one_time} />
                                <FormControlLabel value={props.type.tiered} control={<Radio color={"primary"} />} label={props.type.tiered} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup row aria-label="status" name="status" value={settings["achievements"]["status"]} onChange={(e) => handleStatusChange(e.target.value)}>
                                <FormControlLabel value={props.status.both} control={<Radio color={"primary"} />} label={props.status.both} />
                                <FormControlLabel value={props.status.complete} control={<Radio color={"primary"} />} label={props.status.complete} />
                                <FormControlLabel value={props.status.incomplete} control={<Radio color={"primary"} />} label={props.status.incomplete} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Size</FormLabel>
                            <RadioGroup row aria-label="size" name="size" value={settings["achievements"]["size"]} onChange={(e) => handleSizeChange(e.target.value)}>
                                <FormControlLabel value={props.size.narrow} control={<Radio color={"primary"} />} label={props.size.narrow} />
                                <FormControlLabel value={props.size.wide} control={<Radio color={"primary"} />} label={props.size.wide} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md className={classes.centerVertical}>
                        <TextField
                            placeholder="Search achievements"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={props.onChange}
                            color={"primary"}
                        />
                    </Grid>
                </Grid>
            )}
        </SettingsContext.Consumer>
    )
}